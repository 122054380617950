import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { motion } from "framer-motion"
import { Link } from "gatsby"
export function Shop() {

    return (
        <Link to="/products">
        <motion.svg  className="md:h-96 mx-auto w-3/4 mt-20 md:mt-48" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
            viewBox="0 0 576 576"  >
            <motion.g>
                <motion.g>
                    <motion.g>
                        <motion.g>
                            <motion.path class="st0" d="M539.98,402.56l-170.43-35.49l-34.14,3.85l-48.43-21.94l-91.05,23.52l-26.56-4.82l-141.3,25.79l91.42-80.53
					l-33.46-11.52l23.98-41.77l-65.13-85.52l166.81,38.31c7.86-2.39,15.98-4.87,17.23-5.3c2.78-1.06,2.98-1.02,21.36,3.09
					c8.5,1.9,20.26,4.58,34.95,7.96c20.7,4.77,41.52,9.62,48.22,11.19l60.71-17.02h21.96L548,180.96l-83.05,116.69l-19.96,12.74
					L539.98,402.56z M370.39,350.71l117.65,24.5l-69.46-67.39l34.2-21.84l56.3-79.1l-90.85,21.65h-21.61l-62.75,17.59l-2.22-0.52
					c-34.01-7.95-88.27-20.49-98.97-22.62c-3.16,0.99-9.21,2.85-18.09,5.54l-2.25,0.68l-126.73-29.1l44.43,58.34l-19.63,34.19
					l41.07,14.13l-68.35,60.21l86.26-15.74l25.9,4.7l93.33-24.11l49.67,22.5L370.39,350.71z M235.13,222.19
					c-0.1,0.04-0.26,0.09-0.48,0.17C234.81,222.3,234.97,222.25,235.13,222.19z"/>
                        </motion.g>
                        <motion.path class="st1" d="M65.24,187.13l146.77,33.71c0,0,18.84-5.71,19.97-6.14c1.13-0.43,101.68,23.07,101.68,23.07l61.73-17.3
				h21.79l111.36-26.53l-69.67,97.9l-27.08,17.29l82.23,79.78l-144.04-29.99l-33.13,3.74l-49.05-22.22l-92.19,23.82l-26.23-4.76
				L55.59,380.24l79.89-70.37l-37.26-12.82l21.8-37.98L65.24,187.13z"/>
                    </motion.g>
                </motion.g>
                <motion.g>
                    <motion.g>
                        <motion.polygon class="st2" points="90.6,202.28 237.77,245.7 214.98,275.66 177.21,269.15 214.98,293.89 186.49,343.55 83.43,367.81 
				170.04,303.01 120.55,289.99 138.79,254.82 			"/>
                    </motion.g>
                    <motion.g>
                        <motion.polygon class="st2" points="231.26,223.56 246.89,247.01 218.89,284.78 222.79,292.59 192.4,354.46 233.21,340.78 
				248.58,314.21 263.82,308.87 247,337.77 285.31,329.06 325.03,249.61 287.7,237.67 293.78,251.56 276.84,279.57 259.91,284.12 
				280.1,249.61 			"/>
                    </motion.g>
                    <motion.g>
                        <motion.path class="st2" d="M398.62,232.68l-65.28,18.89l-37.83,76.63l52.77,26.27l36.34-59.7l-6.19-37.99L398.62,232.68z
				 M345.87,308.87l-5.82-8.99l7.01-15.75l4.45,12.37L345.87,308.87z"/>
                    </motion.g>
                    <motion.g>
                        <motion.path class="st2" d="M426.14,290.37l21.63-9.7l49.63-66.57l-94.05,26.46l-15.63,17.58l7.16,39.07l-27,46.4l114.99,29.05
				l-76.27-50.71L426.14,290.37z M452.18,244.89l-19.1,15.85l-6.3-5.86L452.18,244.89z"/>
                    </motion.g>
                </motion.g>
            </motion.g>
        </motion.svg>
        </Link>

    )
}
