import React, { useState } from "react"
import { Swiper, SwiperSlide} from 'swiper/react';
import { GatsbyImage } from "gatsby-plugin-image"

import SwiperCore, {
    Mousewheel
  } from "swiper";
  // Import Swiper React components
  
  // Import Swiper styles
  import 'swiper/css';
  
    
  SwiperCore.use([ Mousewheel]);
  


// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function GallerySwiper(props) {
  return (<Swiper
    draggable={true}
    freeMode={true}
    slidesPerView={1}
    direction={'vertical'}
    initialSlide={1}
    loop={true}
    mousewheel={true}
    className="gallery-swiper">
 {props.products && props.products.images.map((p, index) => (
   <SwiperSlide>
     {console.log(p)}
          <GatsbyImage
          alt={p?.altText ?? "FESSENDEN PRODUCT"}
          image={p?.gatsbyImageData ?? p.src}
          loading={ "lazy" }
          className="product-image"
          
        />
   </SwiperSlide>
 ))}
 </Swiper>
  )
}
