import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLocation } from "@reach/router"
import { motion } from "framer-motion"

export function Contact() {

    return (
        <Link to ="/about">
        <motion.svg className="md:h-96 mx-auto w-3/4 mt-20 md:mt-48" version="1.1" id="CONTACT" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 576 576"
>

        <motion.g>
        <motion.g>
            <motion.polygon class="st0" points="26.6,205.08 193.67,255.4 214.49,222.46 275.21,252.77 339.95,226.49 345.42,238.57 418.2,224.76 
                451,236.27 551.4,212.68 508.53,269.92 479.19,284.6 475.46,321.79 514.86,372.05 384.69,341.56 360.99,349.04 318.38,328.32 
                305.39,341.17 259.01,321.95 212.67,350.87 187.63,335.52 51.13,371.19 83.92,324.73 60.33,301.86 82.2,268.49 		"/>
            <motion.path class="st1" d="M523.28,377.42l-138.46-32.44l-24.09,7.6l-41.69-20.26l-12.88,12.75l-46.84-19.41l-46.63,29.1l-25.59-15.69
                L43.2,376.68l36.39-51.55l-23.52-22.8l21.98-33.55l-61.55-70.2l175.72,52.92l21.07-33.34l62.06,30.97l66.31-26.92l5.7,12.61
                l71.1-13.49l32.72,11.48l108.32-25.45l-48.79,65.16l-28.41,14.21l-3.42,34.06L523.28,377.42z M384.56,338.13l0.88,0.21
                l121.01,28.35l-34.42-43.89l4.05-40.33l30.27-15.14l36.94-49.34l-92.48,21.73l-32.87-11.53l-74.47,14.13l-5.23-11.56l-63.19,25.65
                l-59.38-29.63l-20.56,32.53L36.69,211.57l49.65,56.62L64.6,301.38l23.66,22.94L59.05,365.7l129.11-33.74l24.5,15.02l46.04-28.74
                l45.93,19.03l13.09-12.95l43.53,21.16L384.56,338.13z"/>
        </motion.g>
        <motion.g>
            <motion.g>
                <motion.polygon class="st2" points="68.26,232.29 101.22,272.62 83.99,300.24 108.66,321.42 78.01,352.6 162.46,329.51 137.61,297.43 
                    133.91,303.3 123.85,295.56 130.6,286.43 137.85,292.33 164.9,261.04 			"/>
            </motion.g>
            <motion.g>
                <motion.polygon class="st2" points="211.65,255.4 187.63,276.83 200.57,294.81 189.21,317.54 209.49,330.34 213.81,307.77 230.21,299.7 
                    221.72,324.73 241.71,310.2 245.16,283.3 220.86,268.78 206.47,273.38 			"/>
            </motion.g>
            <motion.g>
                <motion.polygon class="st2" points="212.67,267.32 223.52,238.57 273.57,266.83 323.63,246.77 292.78,284.09 291.91,276.76 
                    273.36,288.19 275.08,307.77 260.84,302.22 248.98,308.9 254.15,279.35 225.21,261.66 			"/>
            </motion.g>
            <motion.g>
                <motion.path class="st2" d="M193.67,264.89l-27.11,2.77l-23.23,27.87l23.23,29.2l18.49-13.81l8.62-17.21l-12.23-18.03L193.67,264.89z
                     M166.56,302.65l-4.1-5.61l3.24-6.04l5.39,4.75L166.56,302.65z"/>
            </motion.g>
            <motion.g>
                <motion.path class="st2" d="M360.99,248.56l-33.66,27.83v-6.46l-15.53,3.01L279,300.99l2.16,10.57l24.6,9.71l9.49-7.98l45.74,22.22
                    l-22.01-37.54L360.99,248.56z M309.64,301.86l-4.32-1.94l6.47-8.41l1.51,3.45L309.64,301.86z"/>
            </motion.g>
            <motion.g>
                <motion.polygon class="st2" points="414.89,240.73 361.82,261.01 345.42,296.82 360.99,325.07 423.96,338.25 391.59,292.87 
                    386.2,298.98 376.06,292.87 386.2,282.15 390.3,286.47 			"/>
            </motion.g>
            <motion.g>
                <motion.polygon class="st2" points="420.97,242.85 398.96,281.72 419.25,264.89 433.92,273.74 433.45,321.42 423.96,327.89 
                    488.72,355.08 451.18,321.85 459.59,273.74 490.49,257.67 487.39,268.49 520.22,231.66 445.57,252.38 			"/>
            </motion.g>
        </motion.g>
    </motion.g>
    
    </motion.svg>
    </Link>
      )
}
