import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { motion } from "framer-motion"
import { Link } from "gatsby"
export function Styling() {

    return (
        <Link to ="/styling">
        <motion.svg className="md:h-96 mx-auto w-3/4 mt-20 md:mt-48" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 576 576">
        <g>
        <g>
            <motion.path
           initial={{
            pathLength:0,
            pathOffset:0,
        
          }}
          animate={{
            pathLength:1,
            
            pathOffset: 15,
        
          }} 
          transition={{
            duration: 5
            
          }}
          class="st0" d="M561.18,376.45L369,342.66l1.82,8.2l-88.85-18.35l-77.37,15.47l0.92-7.33l-74.79,12.19l-13.92-7.83
                L15.14,366.15l59.12-61.44l-18.61-11.18l13.17-27.86l-54-66.11l179.61,47.59l-4.2-12.61l81.96,17.77l107.47-28.79l-4.56,15.51
                l171.24-36.92l-77.14,69.52l-5.24,19.48l5.15,19.11L561.18,376.45z M353.01,327.37l157.33,27.66l-51.98-37.38l-7.14-26.54
                l7.05-26.18l45.6-41.1l-146.4,31.56l4.22-14.34l-89.19,23.89l-63.78-13.83l4.58,13.73L48.32,221.14l34.92,42.75l-11.73,24.82
                l22.29,13.4l-42.25,43.91l67.27-13.98l14.18,7.98l86.75-14.14l-0.84,6.71l63.1-12.62l72.69,15.01L353.01,327.37z"
          />
        </g>
        <g>
            <motion.polygon class="st1" points="31.57,210.35 203.86,256 199.47,242.83 272.34,258.63 370.66,232.29 366.27,247.22 525.09,212.98 
                463.73,268.29 457.58,291.12 463.73,313.94 535.76,365.74 361.01,335.01 362.76,342.91 281.99,326.23 211.76,340.28 
                212.64,333.26 131.87,346.43 117.82,338.52 33.35,356.08 84.03,303.41 63.58,291.12 76.03,264.78 		"/>
        </g>
        <motion.g
      >
            <motion.polygon class="st2" points="151.18,277.95 169.62,252.49 189.81,261.27 211.76,262.14 227.56,288.48 211.76,280.58 202.1,282.34 
                188.93,326.23 136.26,335.89 173.13,313.94 179.28,277.95 164.35,272.68 		"/>
            <motion.polygon class="st2" points="272.34,265.66 304.82,256 283.27,293.22 304.84,278.82 294.21,322.09 278.97,314.68 254.62,323.95 
                265.4,307.31 		"/>
            <motion.polygon class="st2" points="313.6,258.63 352.47,244.1 330.3,267.57 330.93,289.51 317.11,326.23 300.43,319.21 		"/>
            <motion.polygon class="st2" points="334.95,271.67 357.62,246.54 355.36,264.36 371.28,254.1 401.82,255.37 382.51,292.15 399.72,334.83 
                365.24,326.41 371.34,295.59 354.1,303.53 350.53,330.93 324.13,323.6 335.46,294.42 		"/>
            <motion.polygon class="st2" points="248.63,268.29 246.88,277.07 236.34,260.39 211.76,252.49 238.74,298.75 232.7,310.84 230.41,314.81 
                209.17,305.82 223.87,329.51 248.38,326.25 260.64,306.64 267.95,264.78 245.12,259.51 		"/>
            <motion.polygon class="st2" points="55.4,225.02 162.62,253.36 146.77,275.47 119.35,271.54 147.15,288.64 127.48,326.23 59.26,342.32 
                114.87,296.16 78.85,287.77 91.3,261.99 		"/>
            <motion.polygon class="st2" points="444.28,291.47 427.61,291.95 433.97,299.44 437.39,308.55 414.45,292.32 434.64,279.46 443.26,283.7 
                452.05,254.75 485.09,231.45 411.88,251.57 389.87,293.02 404.81,329.52 485.23,348.18 446.27,320.73 		"/>
        </motion.g>
    </g>
    </motion.svg>
    </Link>
      )
}
