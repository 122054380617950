import { graphql, Link } from "gatsby"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Styling } from "../components/styling"
import { Contact } from "../components/contact"
import { Shop } from "../components/shop"

import { motion } from "framer-motion"
import { Swiper, SwiperSlide } from "swiper/react"

import React, { useState, useEffect } from "react"
import $ from "jquery"

import "../styles/taw.css"
import { GallerySwiper } from "../components/gallery-swiper"

import { InventoryItem } from "../components/inventoryitem"

// Import Swiper React components

import SwiperCore, { Mousewheel, Scrollbar } from "swiper"
// Import Swiper React components

// Import Swiper styles
import "swiper/css"
import "swiper/css/scrollbar"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

function SplashScreen(props) {
  const [displaySplash, setDisplaySplash] = React.useState(props.splash)
  useEffect(() => {
    if (document) {
      let videoElement = document.getElementById("intro")
      videoElement.addEventListener("suspend", () => {
        setDisplaySplash(false)
      })
    }

    if (typeof window !== "undefined") {
      if (window.location.hash == "#skip") {
        setDisplaySplash(false)
      }
    }
  }, [])
  return (
    <>
      <div
        className={
          displaySplash === false ? "splash-screen" : "splash-screen-hide"
        }
      >
        <video
          onEnded={() => {
            localStorage.setItem("finished-video", "true")
            setDisplaySplash(true)
          }}
          id={"intro"}
          className={
            displaySplash === false
              ? "splash-screen-video"
              : "splash-screen-hide"
          }
          playsInline
          autoPlay
          muted
        >
          <source src="/intro.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  )
}

export default function IndexPage({ data: { products } }) {
  const [spotlightImage, setSpotlightImage] = useState(null)

  const [activeItem, setActiveItem] = useState(null)

  const [skipSplash, setSkipSplash] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hash == "#skip") {
        setSkipSplash(true)
      }
    }
    if (localStorage.getItem("finished-video") === "true") {
      setSkipSplash(true)
    }
  }, [])

  return (
    <>
      {!skipSplash && <SplashScreen splash={skipSplash}></SplashScreen>}
      <Layout>
        <Seo title="SHOP" />
        <div className=" ">
          <Swiper
            modules={[Mousewheel, Scrollbar]}
            slidesPerView={"auto"}
            centeredSlides={true}
            scrollbar={true}
            mousewheel={true}
            initialSlide={1}
            className="h-screen"
            direction={"horizontal"}
            observer={true}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 50,
                scrollbar: true,
                direction: "horizontal",
              },
            }}
            className="product-swiper"
          >
            <SwiperSlide>
              <Styling className="mt-11 " />
            </SwiperSlide>

            <SwiperSlide>
              <Shop className="flex flex-col" />
            </SwiperSlide>
            <SwiperSlide>
              <Contact className="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    products: allShopifyProduct(
      sort: { fields: publishedAt, order: ASC }
      limit: 24
    ) {
      nodes {
        description
        title
        images {
          gatsbyImageData
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
